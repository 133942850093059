import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}sample`} component={asyncComponent(() => import('./SamplePage'))}/>
      <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./Dashboard'))}/>
      <Route path={`${match.url}invest`} component={asyncComponent(() => import('./Invest'))}/>
      <Route path={`${match.url}bots`} component={asyncComponent(() => import('./Bots'))}/>
      <Route path={`${match.url}exchanges`} component={asyncComponent(() => import('./Exchanges'))}/>
      <Route path={`${match.url}market_watch`} component={asyncComponent(() => import('./MarketWatch'))}/>
      <Route path={`${match.url}strategizer`} component={asyncComponent(() => import('./Strategizer'))}/>
    </Switch>
  </div>
);

export default App;
